import { useRouter } from "next/router";
import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import produce from "immer";
import { useActuals, useAgreements, useApplications, useCounterparty, useUnbalancedAccounts } from "hooks/api";
import { Overview, MyLoans, LoanAlternative, WithdrawMoney } from "@dbt/commons/components/icons/24px";
import { MenuItem } from "@dbt/commons/components/navbar";
import { useUser } from "hooks/contexts";
import { UserReprStatus } from "@dbt/commons/types";
import { applicationPaths } from "constants/paths";
import { LocalesKey } from "types/next-i18next";
import { useTranslation } from "next-i18next";
import { getBookkeepingYears } from "utils/actuals";
import { erpName } from "@dbt/commons/components/erpConnection";
export const MenuContext = createContext<MenuState>({} as MenuState);
interface CustomerPortalMenuItem extends Omit<MenuItem, "label" | "description" | "id"> {
  id: "home" | "application" | "loanapplication" | "drawdown" | "loans" | "reports" | "bookkeeping" | "documents";
  label: LocalesKey["Common"];
  description: LocalesKey["Common"];
}
const images = {
  bookKeeping: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAgVBMVEUAAAAAAAAAAABIJCQ/KhU6OhM8MBg5Lhc/NRU9MxQ+MBQ7LxE+MxY8MBQ7MxM8MRU7MBQ6MxQ9MxQ6MRQ8MRQ8MhQ8MRQ7MhM7MRQ8MRM8MhQ7MRQ7MRM8MRQ8MhQ8MRQ7MRQ7MhQ8MhQ8MhQ7MhQ7MRQ8MRM7MhM8MRQ8MhQ8MhSEDuCIAAAAKnRSTlMAAQIHDA0VFhgZJSstP0BISUpLV3J6e46Vqa2v0tPU1+Lj5O7v8PX4+v1Rj7B9AAAA8klEQVRIx+3U1xKCMBAF0GABu9iwBgRRyf3/D1QYQMdhN2QsDw73DZID7CZBiF8G1Qn6xoQzAPEg2lBEQjqGxD6Q76GIoA1JaEMT0jCEMhwhDEuqDU8qDbn6j0hzglqEndGQD5J5FLoakk8pEwJHDcmnFLFiLXES4NJ+jHh3MWWJ4yNR2LaKgZlSM758WyLoDU/Y5PcHMVZ8x9Jz0RNifMUyu+5I7C2OzKMEfvanWyi1SEvfwe+y63LvVZL/G5e4TtLS44HQkbK9a5xHZek0ccOsnVlaWyjAM9ow7QsQW2Z77GUX1CHPn9kc5D8n+rxPvpsbQx5GyaQAW+kAAAAASUVORK5CYII=",
  documents: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyBAMAAADsEZWCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAG1BMVEUAAAA5Lhc/NRU6MRM8MhQ7MhQ8MRQ7MhM8MhSqkjhzAAAACHRSTlMAFhga1NbX2Wd4qmQAAABsSURBVDjLY2AgD3B0IEAQTpl2AxSZBiRFxbhk2toUcMg0ehTjkGkQQbYJRYYRWROKDAOyJlQZZE2oMsia0GSQNKHJIGlCCQMwCMAp04ApgyYwOGQQMd5AtAy93dZAgsxouA1imQ50QFiGVAAAjaB3vaMMEwoAAAAASUVORK5CYII=",
  application: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyAQMAAAAk8RryAAAACXBIWXMAAAsTAAALEwEAmpwYAAAABlBMVEUAAAA8MhR/vCU3AAAAAXRSTlMAQObYZgAAADNJREFUGNNjYEADjP+B4AES3QAUTKCAbgQag0xTbt6HB8g0QfX/GB4g09Q2n1QaLXzRAACV7E0baSI5QwAAAABJRU5ErkJggg=="
};
const items: CustomerPortalMenuItem[] = [{
  id: "home",
  label: "menuItemOverview",
  icon: <Overview color="currentColor" />,
  path: applicationPaths.home,
  disabled: false,
  active: false,
  description: "menuItemOverviewDescription",
  notifications: undefined
}, {
  id: "application",
  label: "menuItemApplication",
  // eslint-disable-next-line @next/next/no-img-element
  icon: <img width="24px" height="24px" src={images.application} />,
  path: applicationPaths.application,
  disabled: false,
  active: false,
  description: "menuItemApplicationDescription"
}, {
  id: "bookkeeping",
  label: "menuItemBookKeeping",
  // eslint-disable-next-line @next/next/no-img-element
  icon: <img width="24px" height="24px" src={images.bookKeeping} />,
  path: applicationPaths.bookKeeping,
  disabled: false,
  active: false,
  description: "menuItemBookKeepingDescription"
}, {
  id: "documents",
  label: "menuItemDocuments",
  // eslint-disable-next-line @next/next/no-img-element
  icon: <img width="24px" height="24px" src={images.documents} />,
  path: applicationPaths.documents,
  disabled: false,
  active: false,
  description: "menuItemDocumentsDescription"
}, {
  id: "loanapplication",
  label: "menuItemLoanApplication",
  icon: <LoanAlternative color="currentColor" />,
  path: applicationPaths.loanApplication,
  disabled: false,
  active: false,
  description: "menuItemLoanApplicationDescription"
}, {
  id: "drawdown",
  label: "menuItemDrawdown",
  icon: <WithdrawMoney color="currentColor" />,
  path: applicationPaths.drawdown,
  disabled: false,
  active: false,
  description: "menuItemDrawdownDescription"
}, {
  id: "loans",
  label: "menuItemLoans",
  icon: <MyLoans color="currentColor" />,
  path: applicationPaths.loans,
  disabled: false,
  active: false,
  description: "menuItemLoansDescription"
}];
type NotificationState = { [key in CustomerPortalMenuItem["id"]]: number | undefined };
interface ItemPredicateData {
  isClient: boolean;
  hasApplication: boolean;
  userStatus: UserReprStatus;
  hasAgreement: boolean;
  hasLoans: boolean;
}

/**
 * Sorts the menu items array so that how it should be visible in portal.
 *
 * By default, we first move all disabled items after all enabled items.
 * In future, we may be able to manipulate order based on portal status as well.
 *
 * @param menuItems menu items to be sorted.
 * @returns menu items array in order.
 */
const deriveMenuItemOrder = (menuItems: MenuItem[]) => {
  // we re-order disabled items to be appear after all enabled items
  const disabledItems = menuItems.filter(it => it.disabled);
  const enabledItems = menuItems.filter(it => !it.disabled);
  if (disabledItems.length > 0) {
    return [...enabledItems, ...disabledItems];
  }
  return menuItems;
};

/**
 * Returns the visibility state of the menu item based on current portal status.
 *
 * By default: all items will be hidden as indicated by the func in [DEFAULT] key.
 */
const ItemVisiblityPredicates: {
  [key: string]: (status: ItemPredicateData) => boolean;
} = {
  DEFAULT: () => false,
  home: ({
    isClient,
    hasAgreement,
    userStatus
  }) => ["verified", "admin"].includes(userStatus) && (isClient || hasAgreement),
  // overview page is shown for clients only or if there is an agreement.
  drawdown: ({
    userStatus
  }) => ["verified", "admin"].includes(userStatus),
  // drawdown will shown for verified user
  application: ({
    isClient,
    hasApplication
  }) => !isClient && hasApplication,
  reports: () => true,
  // reports will always shown.
  loans: ({
    userStatus,
    hasLoans
  }) => ["verified", "admin"].includes(userStatus) && hasLoans,
  bookkeeping: () => true,
  documents: () => true
};

/**
 * Returns the enable/disable state of the menu item based on current portal status.
 *
 * By default: all items are disabled as indicated by the func in [DEFAULT] key.
 */
const ItemEnablePredicates: {
  [key: string]: (status: ItemPredicateData) => boolean;
} = {
  DEFAULT: () => false,
  home: ({
    isClient,
    hasAgreement,
    userStatus
  }) => ["verified", "admin"].includes(userStatus) && (isClient || hasAgreement),
  drawdown: ({
    hasAgreement
  }) => hasAgreement,
  // drawdown enables only if an agreement does exist.
  application: ({
    isClient,
    hasApplication
  }) => !isClient && hasApplication,
  reports: () => true,
  // reports will always enabled
  loans: ({
    userStatus,
    hasLoans
  }) => ["verified", "admin"].includes(userStatus) && hasLoans,
  // Only available when user is verified and has at least one active loan.
  bookkeeping: () => true,
  documents: () => true
};
type MenuItems = typeof items;
export type MenuItemKey = keyof MenuItems;
export interface MenuState {
  selectedItem?: string;
  selectedItemRef?: MenuItem;
  onItemClick(link: string, navigate?: boolean): void;
  menuLevel: number;
  setMenuLevel(level: number): void;
  menuItems: MenuItem[];
  homePath: string;
  notifications: NotificationState;
}

/**
 * Manipulates visibility and disable flags of each and every menu item.
 *
 * @param menuItems menu items to be manipulated.
 * @param status current status of the portal
 * @returns new menu items array
 */
const manipulateMenuItems = (menuItems: MenuItems, status: ItemPredicateData) => produce(menuItems, draft => {
  draft.forEach(navItem => {
    navItem.visible = (ItemVisiblityPredicates[navItem.id] || ItemVisiblityPredicates.DEFAULT)(status);
    navItem.disabled = !(ItemEnablePredicates[navItem.id] || ItemEnablePredicates.DEFAULT)(status);
  });
});
export const MenuProvider = ({
  children
}: PropsWithChildren) => {
  const router = useRouter();
  const [menuLevel, setMenuLevel] = useState(0);
  const {
    data: agreements,
    loading: agreementsLoading
  } = useAgreements();
  const {
    data: counterparty,
    loading: counterpartyLoading
  } = useCounterparty();
  const {
    data: applications,
    loading: applicationsLoading
  } = useApplications();
  const loading = agreementsLoading || counterpartyLoading || applicationsLoading;
  const {
    currentRepresentation
  } = useUser();
  const userStatus = currentRepresentation.status;
  const hasAgreement = !!agreements?.length;
  // based on this comment, we should show only at least one active loan exists
  // https://linear.app/dbt/issue/DBT-1694#comment-5e8cac23
  const hasLoans = (counterparty?.activeContracts || 0) > 0;
  const hasApplication = !!applications;
  const isClient = Boolean(counterparty?.dbtCustomerCoreId);
  const {
    t
  } = useTranslation("Common");
  const {
    data: actuals,
    loading: loadingActuals
  } = useActuals();
  const {
    data: unbalancedAccounts,
    loading: unbalancedDataLoading
  } = useUnbalancedAccounts();
  const {
    hasEnoughYearsOfBookkeepingData
  } = getBookkeepingYears(actuals, unbalancedAccounts);
  const [notifications, setNotifications] = useState({} as NotificationState);

  // adding noticfication batch to both reports and application page because sie upload is used in both places
  useEffect(() => {
    if (!loadingActuals && !unbalancedDataLoading) {
      setNotifications(state => ({
        ...state,
        reports: counterparty && counterparty.connectedErp === erpName.manualSie && !hasEnoughYearsOfBookkeepingData ? 1 : undefined,
        application: counterparty && counterparty.connectedErp === erpName.manualSie && !hasEnoughYearsOfBookkeepingData ? 1 : undefined
      }));
    }
    // Do not include counterparty as dependency because otherwise the notification is immediately shown
    // when connectedErp changes, but it is supposed to be shown on next refresh of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEnoughYearsOfBookkeepingData, loadingActuals, unbalancedDataLoading]);
  const menuItems = useMemo<MenuItem[]>(() => {
    const status = {
      isClient,
      hasApplication,
      userStatus,
      hasAgreement,
      hasLoans
    };
    return deriveMenuItemOrder(manipulateMenuItems(items, status).map(it => ({
      ...it,
      label: t(it.label),
      description: t(it.description),
      active: it.path !== applicationPaths.home ? router.pathname.startsWith(it.path) : it.path === router.pathname,
      notifications: notifications[it.id]
    })));
  }, [isClient, hasApplication, userStatus, hasAgreement, hasLoans, t, router.pathname, notifications]);
  const handleItemClick = useCallback((link: string, navigate = true) => {
    if (navigate) {
      router.push(link, undefined, {
        shallow: true
      });
    }
    setMenuLevel(0); // closes menu overlay on mobile
  }, [router]);
  const activatedItemRef = useMemo(() => {
    return menuItems.find(it => it.path === router.pathname);
  }, [router.pathname, menuItems]);
  const firstAvailable = useMemo(() => menuItems.find(m => m.visible && !m.disabled), [menuItems]);
  const homePath = firstAvailable?.path ?? applicationPaths.home;

  // redirect to a valid page, if user should not allow to access the activated page.
  useEffect(() => {
    if (!loading && activatedItemRef && (!activatedItemRef.visible || activatedItemRef.disabled)) {
      // we are about to move into a page which is not visible for the current user
      if (firstAvailable) {
        router.push(firstAvailable.path);
      }
    }
  }, [activatedItemRef, menuItems, router, loading, firstAvailable]);
  const value = useMemo(() => ({
    selectedItem: router.pathname,
    selectedItemRef: activatedItemRef,
    onItemClick: handleItemClick,
    setMenuLevel,
    menuLevel,
    menuItems: menuItems,
    homePath,
    notifications
  }), [handleItemClick, setMenuLevel, menuLevel, menuItems, router.pathname, activatedItemRef, homePath, notifications]);
  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};