import { config } from "@dbt/aws-helper";
import { z } from "zod";
/// Strict conversion of a string to a number, returning NaN if string is not a proper number
export function convertStringToNumber(input: string): number {
  if (!input || input.trim().length == 0) return NaN;else return Number(input);
}

/// Returns true for 1, '1', true, 'true' (case-insensitive); otherwise false
export function primitiveToBoolean(value: string | number | boolean | null | undefined): boolean {
  if (typeof value === "string") return value.toLowerCase() === "true" || !!+value;
  // here we parse to number first
  else return !!value;
}
export const configCache = config.ssmParametersJson("smtp", z.object({
  portalBackendHost: z.string()
}), {
  portalBackendHost: "/service/portal-backend/host"
}, {
  maxAgeInSeconds: 3600
});
export function getEnvironmentClass() {
  return getEnv("ENVIRONMENT_CLASS", "unknown");
}
export async function getPortalBackendUrl(): Promise<string> {
  const host = (await configCache.get()).portalBackendHost;
  return `https://${host}`;
}
export function getEnv(key: string, defaultStr: string): string;
export function getEnv(key: string, defaultNum: number): number;
export function getEnv(key: string, defaultBool: boolean): boolean;
export function getEnv(key: string): string;
export function getEnv(key: string, defaultVal?: string | number | boolean): string | number | boolean {
  const envVal = process.env[key];
  if (defaultVal === undefined) {
    // default type is string
    if (!envVal) throw new Error(`Environment variable \`${key}\` is required`);else return envVal;
  } else {
    if (envVal === undefined || envVal === null) return defaultVal;else {
      if (typeof defaultVal === "number") {
        const val = convertStringToNumber(envVal);
        if (isNaN(val)) throw new Error(`Environment variable '${key}' is not a valid number: '${envVal}'`);
        return val;
      } else if (typeof defaultVal === "boolean") {
        return primitiveToBoolean(envVal);
      } else return envVal;
    }
  }
}