import type { DBTError } from "@dbt/commons/types/error";
export enum FortnoxState {
  LOADING,
  ABORT,
  SUCCESS,
  ORGNUMBER_MISMATCH,
  ERROR,
  INITIAL,
  SYNCING,
}
export type FortnoxError = DBTError;
export enum FortnoxErrorCode {
  MissingParamsError = "MissingParamsError",
  OrgNumberMismatchError = "OrgNumberMismatchError",
  FortnoxAuthError = "FortnoxAuthError",
  FortnoxTooManyRequestsError = "FortnoxTooManyRequestsError",
  FortnoxTimeoutError = "FortnoxTimeoutError",
  FortnoxGeneralError = "FortnoxGeneralError",
  StorageAPIError = "StorageAPIError",
  StorageAPITooManyRequestsError = "StorageAPITooManyRequestsError",
}
export const ErrorsToExcludeAndShowAsSuccessful = ["BalanceError"];