import { Job, JobSieData } from "@dbt/commons/types";
import { useUser } from "hooks/contexts";
import { createContext, PropsWithChildren, useEffect, useMemo, useState } from "react";
interface ContextState {
  completedUploads: Job<JobSieData>[];
  setCompletedUploads: (jobs: Job<JobSieData>[]) => void;
}
export const TemporaryUploadStateContext = createContext<ContextState>({} as ContextState);
export const TemporaryUploadStateProvider = ({
  children
}: PropsWithChildren) => {
  const {
    currentRepresentation
  } = useUser();
  const [completedUploads, setCompletedUploads] = useState<Job<JobSieData>[]>([]);
  useEffect(() => {
    if (currentRepresentation) {
      setCompletedUploads([]);
    }
  }, [currentRepresentation]);
  const value = useMemo(() => ({
    completedUploads,
    setCompletedUploads
  }), [completedUploads, setCompletedUploads]);
  return <TemporaryUploadStateContext.Provider value={value}>
      {children}
    </TemporaryUploadStateContext.Provider>;
};