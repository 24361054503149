import { Agreement, CpInvoices, CpLoansOverview, ErpOrgSourceUnbalancedAccounts } from "@dbt/commons/types";
import { createBudAct, FinDataSet, transformBudActData } from "@dbt/commons/utils";
import { ApiRoutes as CounterPartiesRoutes, getActuals, getCounterparty, getFiles, getInvoices, getLoansOverview } from "api/client/counterparties";
import { ApiRoutes as GoogleRoutes, getCalendarEvents } from "api/client/google";
import { ApiRoutes as AgreementRoutes, getAgreements } from "api/client/agreements";
import { ApiRoutes as ApplicationRoutes, getApplications } from "api/client/applications";
import { ApiRoutes as ErpAccountingRoutes, getUnabalancedAccounts } from "api/client/accounting";
import { ApiRoutes as DrawdownRoutes, getDrawdowns } from "api/client/drawdown";
import type { calendar_v3 } from "@dbt/commons/api/server/google";
import { useCallback } from "react";
import useSWR from "swr";
import { getYears } from "utils/actuals";
import { useUser } from "./contexts";
import { transformResponse } from "./utils";
export function useMeetings() {
  const {
    user: {
      email
    },
    currentRepresentation: {
      email: ceEmail
    }
  } = useUser();
  const response = useSWR<{
    meetings: calendar_v3.Schema$Event[];
  }>(GoogleRoutes.getCalendarEvents(email, ceEmail), () => getCalendarEvents(email, ceEmail));
  const transformedResponse = transformResponse(response);
  const hasMeetings = transformedResponse.data && transformedResponse.data.meetings.length > 0;
  return {
    ...transformedResponse,
    hasMeetings
  };
}
export function useCounterparty() {
  const {
    currentRepresentation: {
      orgId
    }
  } = useUser();
  const response = useSWR(CounterPartiesRoutes.getCounterparty(orgId), () => getCounterparty(orgId));
  return transformResponse(response);
}
export function useFiles() {
  const {
    currentRepresentation: {
      orgId
    }
  } = useUser();
  const response = useSWR([CounterPartiesRoutes.getFiles, orgId], getFiles);
  return transformResponse(response);
}
export function useInvoices() {
  const {
    currentRepresentation: {
      orgId
    }
  } = useUser();
  const response = useSWR<CpInvoices>([CounterPartiesRoutes.getInvoices, orgId], getInvoices);
  return transformResponse(response);
}
export function useLoansOverview(returnAllLoans?: boolean) {
  const {
    currentRepresentation: {
      orgId
    }
  } = useUser();
  const response = useSWR<CpLoansOverview>([CounterPartiesRoutes.getLoansOverview, orgId, returnAllLoans], () => getLoansOverview().then(data => ({
    ...data,
    loans: returnAllLoans ? data.loans : data.loans.filter(d => d.loanContract.outstandingAmount > 0)
  })));
  return transformResponse(response);
}
export function useUnbalancedAccounts() {
  const {
    currentRepresentation: {
      orgId
    }
  } = useUser();
  const response = useSWR<ErpOrgSourceUnbalancedAccounts[] | null>(ErpAccountingRoutes.getUnabalancedAccounts(orgId), () => getUnabalancedAccounts(orgId));
  return transformResponse(response);
}
export function useActuals(years?: number) {
  const {
    currentRepresentation: {
      orgId
    }
  } = useUser();
  const {
    fromDate,
    toDate
  } = getYears(years);
  const actualsFetcher = useCallback(async () => {
    const response = await getActuals(fromDate, toDate);
    return transformBudActData(createBudAct(response.actuals)) || null;
  }, [fromDate, toDate]);
  const response = useSWR<FinDataSet | null>([CounterPartiesRoutes.getActuals(fromDate, toDate), orgId], actualsFetcher);
  return transformResponse(response);
}
export function useAgreements() {
  const {
    currentRepresentation: {
      orgId
    }
  } = useUser();
  const response = useSWR<Agreement[]>(AgreementRoutes.getAgreements(orgId), () => getAgreements(orgId));
  return transformResponse(response);
}
export function useApplications() {
  const {
    user: {
      email
    },
    currentRepresentation: {
      orgId
    }
  } = useUser();
  const response = useSWR(ApplicationRoutes.getApplications(orgId, email), () => getApplications(orgId, email));
  return transformResponse(response);
}
export function useDrawdowns(agreementId: string) {
  const response = useSWR(DrawdownRoutes.getDrawdown(agreementId), () => getDrawdowns(agreementId));
  return transformResponse(response);
}