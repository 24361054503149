import formidable from "formidable";
import fs from "fs";
import readline from "readline";
import iconv from "iconv-lite";
import { CustomError } from "./api-server";
import { SIEFileErrorCode } from "../types/error";
const SIE_MANDATORY_TAGS = ["flagga", "format", "program", "gen"];
// total number of lines which will have at least one of above mandatory tags.
const SIE_MANDATORY_TAGS_MAX_LINES = 10;
export interface SIEMetadata {
  orgNumber?: string;
  type?: number;
}
export const ensureOrgNoFormat = (orgNo: string) => {
  const cleanOrgNo = orgNo.trim();
  if (cleanOrgNo.length === 11) {
    // Regular Swedish org no format 555555-1234
    return cleanOrgNo;
  } else if (cleanOrgNo.length === 10) {
    // Assuming Swedish org no without dash (5555551234 -> 555555-1234)
    return `${cleanOrgNo.substring(0, 6)}-${cleanOrgNo.substring(6, cleanOrgNo.length)}`;
  } else {
    return cleanOrgNo;
  }
};
function hasSurrogatePairInText(text: string) {
  return text.codePointAt(0) === 0; // First character of surrogate pair for symbol # is zero.
}
export function parseSIEMetadata(file: formidable.File): Promise<SIEMetadata> {
  return new Promise((resolve, reject) => {
    const metadata: SIEMetadata = {};
    let foundMandatoryFlags = false;
    let count = 0;
    let possibleInvalidEncoding = false;

    // according to the documentation we need to decode before processing.
    // https://dbtcap.atlassian.net/wiki/spaces/TECH/pages/19038209/How+to+SIE#How-to-manually-fix-problems
    const fileStream = fs.createReadStream(file.filepath).pipe(iconv.decodeStream("cp437")).pipe(iconv.encodeStream("utf8"));
    const rl = readline.createInterface({
      input: fileStream,
      crlfDelay: Infinity
    });
    rl.on("line", function (line: string) {
      if (line.match(/\s*#/)) {
        const val = parser._parseLine(line.replace(/^\s*/, "").replace(/\s*$/, ""));
        count++;
        if (!foundMandatoryFlags) {
          if (count < SIE_MANDATORY_TAGS_MAX_LINES) {
            if (hasSurrogatePairInText(val.etikett)) {
              possibleInvalidEncoding = true;
            }
          }
          if (SIE_MANDATORY_TAGS.includes(val.etikett)) {
            foundMandatoryFlags = true;
          }
        }

        // we can safely assume for now orgnr finds after sie file type attribute is found.
        // Because, sietyp is optional for type-1 files.
        if (val.etikett === "orgnr") {
          metadata.orgNumber = ensureOrgNoFormat(val.orgnr);
          rl.close();
        } else if (val.etikett === "sietyp") {
          metadata.type = parseInt(val.typnr);
        } else if (val.etikett === "program") {
          const program = val.programnamn.trim().toLowerCase();
          if (program.match(/fortnox/)) {
            reject(new CustomError(SIEFileErrorCode.FortnoxIntegrationError, 400));
          } else if (program.match(/visma/)) {
            reject(new CustomError(SIEFileErrorCode.VismaIntegrationError, 400));
          }
        }
      }
    });
    rl.on("close", function () {
      if (!foundMandatoryFlags) {
        if (possibleInvalidEncoding) {
          reject(new CustomError("The file uses an unsupported file encoding! Please contact IT.", 400));
        }
        reject(new CustomError(SIEFileErrorCode.CorruptedFileError, 400));
      }
      if (!metadata.orgNumber) {
        reject(new CustomError(SIEFileErrorCode.OrgNumberMissingError, 400));
      }
      switch (metadata.type) {
        case 2:
        case 4:
          resolve(metadata);
        case 3:
          reject(new CustomError(SIEFileErrorCode.InvalidSIETypeError, 400));
        case 1:
        default:
          reject(new CustomError(SIEFileErrorCode.InvalidSIETypeError, 400));
      }
      resolve(metadata);
    });
  });
}
interface TokenType {
  type: string;
  value?: any;
}
const parser = {
  _parseLine: function (line: string) {
    const tokens = parser._tokenizer(line);
    const token = tokens[0];
    const etikett = token?.value?.replace(/^#/, "").toLowerCase();
    const row = {
      etikett: etikett
    };
    return parser._parseAttrs(row, tokens.slice(1));
  },
  _Tokens: {
    ELEMENT: "#",
    BEGINARRAY: "{",
    ENDARRAY: "}",
    STRING: '"',
    ARRAY: "{}"
  },
  _tokenizer: function (line: string): TokenType[] {
    const tokens = [] as TokenType[];
    let consume = false;
    let quoted = false;
    for (let i = 0; i < line.length; i++) {
      if (consume) {
        if (quoted) {
          if (line[i] === "\\" && i + 1 < line.length && line[i + 1] === '"') {
            tokens[tokens.length - 1].value += line[++i];
          } else {
            quoted = consume = line[i] !== '"';
            if (consume) {
              tokens[tokens.length - 1].value += line[i];
            }
          }
        } else {
          consume = line[i] !== " " && line[i] !== "\t" && line[i] !== "}";
          if (consume) {
            tokens[tokens.length - 1].value += line[i];
          } else if (line[i] === "}") {
            tokens[tokens.length] = {
              type: parser._Tokens.ENDARRAY
            };
          }
        }
      } else {
        if (line[i] === "#") {
          consume = true;
          tokens[tokens.length] = {
            type: parser._Tokens.ELEMENT,
            value: ""
          };
        } else if (line[i] === "{") {
          tokens[tokens.length] = {
            type: parser._Tokens.BEGINARRAY
          };
        } else if (line[i] === "}") {
          tokens[tokens.length] = {
            type: parser._Tokens.ENDARRAY
          };
        } else if (line[i] === '"') {
          consume = quoted = true;
          tokens[tokens.length] = {
            type: parser._Tokens.STRING,
            value: ""
          };
        } else if (line[i] !== " " && line[i] !== "\t") {
          consume = true;
          tokens[tokens.length] = {
            type: parser._Tokens.STRING,
            value: line[i]
          };
        }
      }
    }
    return tokens;
  },
  _parseAttrs: function (row: any, tokens: TokenType[]) {
    if (parser._Elements[row.etikett]) {
      for (let i = 0; i < parser._Elements[row.etikett].length; i++) {
        if (typeof parser._Elements[row.etikett][i] == "object") {
          parser._parseArray(tokens, i, parser._Elements[row.etikett][i]);
          parser._addAttr(row, parser._Elements[row.etikett][i].name, tokens, i);
        } else {
          parser._addAttr(row, parser._Elements[row.etikett][i], tokens, i);
        }
      }
    }
    return row;
  },
  _parseArray: function (tokens: TokenType[], start: number, attrDef: any) {
    for (let i = start + 1; i < tokens.length; i++) {
      if (tokens[i].type === parser._Tokens.ENDARRAY) {
        tokens[start] = {
          type: parser._Tokens.ARRAY,
          value: parser._valuesOnly(tokens.splice(start, i - start).slice(1))
        };
        const a: any[] = [];
        for (let j = 0; j < tokens[start].value.length - attrDef.type.length + 1; j += attrDef.type.length) {
          const o = {} as any;
          for (let k = 0; k < attrDef.type.length; k++) {
            o[attrDef.type[k]] = tokens[start].value[j + k];
          }
          a[a.length] = o;
        }
        tokens[start].value = attrDef.many ? a : a[0] || null;
      }
    }
  },
  _addAttr: function (obj: any, attr: string, tokens: TokenType[], pos: number) {
    if (pos < tokens.length) {
      obj[attr] = tokens[pos].value;
    }
  },
  _valuesOnly: function (tokens: TokenType[]) {
    const va: any[] = [];
    for (let i = 0; i < tokens.length; i++) {
      va[va.length] = tokens[i].value;
    }
    return va;
  },
  _Elements: {
    adress: ["kontakt", "utdelningsadr", "postadr", "tel"],
    bkod: ["SNI-kod"],
    dim: ["dimensionsnr", "namn"],
    enhet: ["kontonr", "enhet"],
    flagga: ["x"],
    fnamn: ["företagsnamn"],
    fnr: ["företagsid"],
    format: ["PC8"],
    ftyp: ["Företagstyp"],
    gen: ["datum", "sign"],
    ib: ["årsnr", "konto", "saldo", "kvantitet"],
    konto: ["kontonr", "kontonamn"],
    kptyp: ["typ"],
    ktyp: ["kontonr", "kontotyp"],
    objekt: ["dimensionsnr", "objektnr", "objektnamn"],
    oib: ["årsnr", "konto", {
      name: "objekt",
      type: ["dimensionsnr", "objektnr"]
    }, "saldo", "kvantitet"],
    omfattn: ["datum"],
    orgnr: ["orgnr", "förvnr", "verknr"],
    oub: ["årsnr", "konto", {
      name: "objekt",
      type: ["dimensionsnr", "objektnr"]
    }, "saldo", "kvantitet"],
    pbudget: ["årsnr", "period", "konto", {
      name: "objekt",
      type: ["dimensionsnr", "objektnr"]
    }, "saldo", "kvantitet"],
    program: ["programnamn", "version"],
    prosa: ["text"],
    psaldo: ["årsnr", "period", "konto", {
      name: "objekt",
      type: ["dimensionsnr", "objektnr"]
    }, "saldo", "kvantitet"],
    rar: ["årsnr", "start", "slut"],
    res: ["års", "konto", "saldo", "kvantitet"],
    sietyp: ["typnr"],
    sru: ["konto", "SRU-kod"],
    taxar: ["år"],
    trans: ["kontonr", {
      name: "objektlista",
      type: ["dimensionsnr", "objektnr"],
      many: true
    }, "belopp", "transdat", "transtext", "kvantitet", "sign"],
    rtrans: ["kontonr", {
      name: "objektlista",
      type: ["dimensionsnr", "objektnr"],
      many: true
    }, "belopp", "transdat", "transtext", "kvantitet", "sign"],
    btrans: ["kontonr", {
      name: "objektlista",
      type: ["dimensionsnr", "objektnr"],
      many: true
    }, "belopp", "transdat", "transtext", "kvantitet", "sign"],
    ub: ["årsnr", "konto", "saldo", "kvantitet"],
    underdim: ["dimensionsnr", "namn", "superdimension"],
    valuta: ["valutakod"],
    ver: ["serie", "vernr", "verdatum", "vertext", "regdatum", "sign"]
  } as any,
  Universal: [{
    etikett: "dim",
    dimensionsnr: "1",
    namn: "Kostnadsställe / resultatenhet"
  }, {
    etikett: "underdim",
    dimensionsnr: "2",
    namn: "Kostnadsbärare",
    superdimension: "1"
  }, {
    etikett: "dim",
    dimensionsnr: "6",
    namn: "Projekt"
  }, {
    etikett: "dim",
    dimensionsnr: "7",
    namn: "Anställd"
  }, {
    etikett: "dim",
    dimensionsnr: "8",
    namn: "Kund"
  }, {
    etikett: "dim",
    dimensionsnr: "9",
    namn: "Leverantör"
  }, {
    etikett: "dim",
    dimensionsnr: "10",
    namn: "Faktura"
  }]
};