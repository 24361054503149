import { type SWRResponse } from "swr";
export function transformResponse<T>(response: SWRResponse<T>) {
  const {
    error,
    data
  } = response;
  const loading = data === undefined && error === undefined;
  return {
    ...response,
    loading
  };
}