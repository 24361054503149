import { createContext, PropsWithChildren, useCallback, useMemo, useState } from "react";
export interface DrawerState {
  showDrawer(id: string): void;
  hideDrawer(id: string): void;
  toggleDrawer(id: string): void;
  drawerId?: string;
}
export const DrawerContext = createContext<DrawerState>({} as DrawerState);
export const DrawerProvider = ({
  children
}: PropsWithChildren) => {
  const [drawerQueue, setDrawerQueue] = useState<string[]>([]);
  const showDrawer = useCallback((id: string) => {
    setDrawerQueue(state => [id, ...state]);
  }, []);
  const hideDrawer = useCallback((id: string) => {
    setDrawerQueue(state => state.filter(dId => dId !== id));
  }, []);
  const toggleDrawer = useCallback((id: string) => {
    setDrawerQueue(state => {
      if (state.includes(id)) {
        return state.filter(dId => dId !== id);
      } else {
        return [id, ...state];
      }
    });
  }, []);
  const drawerId = useMemo(() => drawerQueue[0], [drawerQueue]);
  const value = useMemo(() => ({
    showDrawer,
    hideDrawer,
    toggleDrawer,
    drawerId
  }), [hideDrawer, drawerId, showDrawer, toggleDrawer]);
  return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
};