import { DocumentRepositoryItem, DocumentRepositorySlot } from "../types";

// Needs to be updated if slots change on backend
export type SlotId = "STRUCTURE" | "MANUAL-SIE" | "OTHER" | "UC";
export interface Slots {
  slotId: SlotId;
  displayNameEN: string;
  displayNameSV: string;
  allowUpload: boolean; // Select options to which Slot when uploading via Document tab
}
export const slotIds = {
  structure: "STRUCTURE",
  manualSie: "MANUAL-SIE",
  other: "OTHER",
  uc: "UC"
} as const;
export const slots: Slots[] = [{
  slotId: "STRUCTURE",
  displayNameEN: "Ownership structure",
  displayNameSV: "Ägarstruktur och organisationsstruktur",
  allowUpload: true
}, {
  slotId: "MANUAL-SIE",
  displayNameEN: "Manually uploaded SIE files",
  displayNameSV: "Manuellt uppladdade SIE-filer",
  allowUpload: false
}, {
  slotId: "OTHER",
  displayNameEN: "Other documents",
  displayNameSV: "Övrigt dokument",
  allowUpload: true
},
// only visible in staffportal
{
  slotId: "UC",
  displayNameEN: "UC",
  displayNameSV: "UC",
  allowUpload: false
}];
export const getSlotDisplayName = (slotId: string, lang: "EN" | "SV") => {
  return slots.filter(slot => slot.slotId === slotId).map(slot => lang === "EN" ? slot.displayNameEN : slot.displayNameSV);
};
export interface Documents {
  originalSlotId: string;
  blobId: string;
  documentId: string;
  originalFileName: string;
  originalMediaType: string;
  createdBy: string | undefined;
  createdAt: string;
}
export interface DocumentsBySlot {
  slotId: string;
  documents: Documents[];
}
const getDocuments = (originalSlotId: string, documents: DocumentRepositoryItem[]): Documents[] => {
  return documents
  // We have filtered documents which has at least one version.
  // Otherwise, it has been failing in map function.
  .filter(doc => doc.versions.length > 0).map(document => {
    const {
      originalFileName,
      createdByUser,
      createdByStaff,
      created,
      blobId,
      originalMediaType
    } = document.versions[0];
    const createdBy = createdByStaff ? `${createdByStaff.firstName} ${createdByStaff.lastName}` : createdByUser?.userName;
    return {
      originalSlotId,
      blobId,
      documentId: document.documentId,
      originalFileName,
      originalMediaType,
      createdBy,
      createdAt: created.slice(0, 10)
    };
  });
};
const assignUnknownSlotsToOthers = (apiRepoSlot: DocumentRepositorySlot) => {
  return slots.some(slotObj => slotObj.slotId === apiRepoSlot.slotId) ? apiRepoSlot.slotId : "OTHER";
};
export const createDocumentList = (apiRes: DocumentRepositorySlot[]) => {
  return apiRes.reduce((accSlots, currSlot) => {
    const slotId = assignUnknownSlotsToOthers(currSlot);
    const documents = getDocuments(currSlot.slotId, currSlot.documents);
    const slotIndex = accSlots.findIndex(slot => slot.slotId === slotId);
    if (slotIndex === -1) {
      accSlots.push({
        slotId,
        documents
      });
    } else {
      accSlots[slotIndex].documents.push(...documents);
    }
    return accSlots;
  }, [] as DocumentsBySlot[]);
};