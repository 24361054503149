/// Defines the data types of the data payloads returned by the REST API endpoints.
/// Note that many endpoints return an array of elements of the type described here.
/// This file is also used to generate JSON schemas, some elements have annotations
/// for the JSON schema generator.

export interface DwTotalLoanAmounts {
  totalDisbursed: number;
  outstandingBalance: number;
  paidPrincipal: number;
  paidInterest: number;
  paidCharges: number;
}
export interface DwTotalsHistory {
  date: string;
  totalDisbursed: number;
  outstandingBalance: number;
}
export interface BookSliceScope {
  nofCustomers: number;
  nofActiveLoans: number;
  nofClosedLoans: number;
  oldestLoanContractDate: string;
  newestLoanContractDate: string;
}
export interface LoanBookKpis {
  // Book's origination KPIs
  totalDisbursed: number;
  avgTicketAmount: number;
  weightedAvgOrigUcPdPerc: number;

  // Book's current status KPIs
  outstandingBalance: number;
  weightedAvgUcPdPerc: number;
  avgCurrentDbtInterestRate: number;
  avgCurrentBaseInterestRate: number;
  nofPerformingLoans: number;
  paidPrincipal: number;
  paidInterest: number;
  paidCharges: number;
}
export interface CustomerBookKpis {
  totalDisbursed: number;
  totalOutstanding: number;
  nofRecurringCustomers: number;
  recurringCustomersDisbursed: number;
  recurringCustomersOutstanding: number;
}
export type ContractStatus = "active" | "closed";
export interface LoanBookReport {
  // Data pivot parameters (one record per unique value)
  contractStatus: ContractStatus;

  // Scope of this data slice
  sliceScope: BookSliceScope;

  // Report data
  sliceData: LoanBookKpis;
}
export interface CustomerBookReport {
  // Data pivot parameters (one record per unique value)
  contractStatus: ContractStatus;

  // Scope of this data slice
  sliceScope: BookSliceScope;

  // Report data
  sliceData: CustomerBookKpis;
}
export interface DwBudgetForecast {
  date: string;
  budgetAmount: number;
}
export interface DwAmortizationSchedule {
  counterpartyId: string;
  organisationName: string;
  contractId: string;
  paymentDueOn: string;
  dueAmount: number;
}
export interface DwCustomerHeadline {
  orgId: string;
  counterpartyId: string;
  /** @nullable */
  limitGroup: string | null;
  organisationName: string;
  activeContracts: number;
  totalContracts: number;
  contractIds: string;
  /** @nullable */
  ucDate: string | null;
  /** @nullable */
  ucScore: string | null;
  /** @nullable */
  connectedErp: string | null;
  /** @nullable */
  erpConnectedAt: string | null;
  /** @nullable */
  erpConnectedBy: string | null;
  /** @nullable */
  erpDataLastUpdatedAt: string | null;
  /** @nullable */
  nextReviewDate: string | null;
  /** @nullable */
  hubspotOrgId: string | null;
  /** @nullable */
  stafferEmail: string | null;
  /** @nullable */
  stafferRole: string | null;
  /** @nullable */
  activeDeals: string[] | null;
  /** @nullable */
  firstManualSie: string | null;
  /** @nullable */
  lastManualSie: string | null;
  /** @nullable */
  fyFirstMonths: string[] | null;
  /** @nullable */
  fyLastMonths: string[] | null;
}
export interface DwCustomer {
  counterpartyId: string;
  organisationName: string;
  /** @nullable */
  limitGroup: string | null;
  /** @nullable */
  nace: string | null;
  /** @nullable */
  naceCode: string | null;
  /** @nullable */
  registrationDate: string | null;
  /** @nullable */
  daysExist: number | null;
  orgCity: string;
  /** @nullable */
  geographyAdj: string | null;
  orgId: string;
  /** @nullable */
  ucDate: string | null;
  /** @nullable */
  ucPercentage: number | null;
  /** @nullable */
  connectedErp: string | null;
  /** @nullable */
  erpConnectedAt: string | null;
  /** @nullable */
  erpConnectedBy: string | null;
  /** @nullable */
  nextReviewDate: string | null;
  /** @nullable */
  hubspotOrgId: string | null;
  /** @nullable */
  stafferEmail: string | null;
  /** @nullable */
  stafferRole: string | null;
  /** @nullable */
  fyFirstMonths: string[] | null;
  fyLastMonths: string[] | null;
  /** @nullable */
  fyMetadata: FyMetadata[];
  /** @nullable */
  firstManualSie: string | null;
  /** @nullable */
  lastManualSie: string | null;
}
export interface DwLoanContract {
  counterpartyId: string;
  contractId: string;
  auraContractStatus: string;
  contractStatus: string;
  contractCategory: string;
  sanctionedAmount: number;
  valueDate: string;
  maturityDate: string;
  /** @nullable */
  closeDate: string | null;
  interestRate: number;
  baseRate: number;
  productCode: string;
  /** @nullable */
  nextRepaymentDate: string | null;
  outstandingAmount: number;
  firstPrincipalDate: string;
  dbtInv: string;
  /** @nullable */
  relatedCollateralIds: string | null;
  /** @nullable */
  dealId?: string | null;
}
export interface DwLoan {
  counterpartyId: string;
  contractId: string;
  contractStatus: string;
  contractCategory: string;
  interestRate: number;
  valueDate: string; // disbursement date
  /** @nullable */
  pdDbt: number | null;
  maturityDate: string;
  outstandingAmount: number;
  dbtInv: string;
  sanctionedAmount: number;
  /** @nullable */
  dealId?: string | null;
}
export interface DwInvoice {
  counterpartyId: string;
  orgId: string;
  contractId: string;
  invoiceNo: string;
  /** @nullable */
  dbtInv: string | null; // in principle required, but may be missing due to manual error
  bankTransfer: string;
  ocr: string;
  invoiceDate: string;
  dueDate: string;
  /** @nullable */
  paidDate: string | null;
  daysOverdue: number;
  dueAmount: number;
  /** @nullable */
  paidAmount: number | null;
  amountDiff: number;
}
export interface DwLoanEcl {
  contractId: string;
  outstandingAmount: number;
  classification: string;
  pdDbt: number;
  eclDate: string;
  lgd: number;
  /** @nullable */
  ecl: number | null;
}
export interface DwLoanPaymentStatus {
  contractId: string;
  overdueNotPaidPrincipal: number;
  overdueNotPaidInterest: number;
  latePaymentInterest: number;
  /** @nullable */
  principalDaysArrears: number | null;
  /** @nullable */
  interestDaysArrears: number | null;
}
export interface DwCustLoanPaymentStatus {
  counterpartyId: string;
  organisationName: string;
  contractId: string;
  overdueNotPaidPrincipal: number;
  overdueNotPaidInterest: number;
  latePaymentInterest: number;
  /** @nullable */
  principalDaysArrears: number | null;
  /** @nullable */
  interestDaysArrears: number | null;
  outstandingBalance: number;
}
export interface DwLoanPaymentSchedule {
  // Currently used by multiple queries some of which doesn't return orgId!
  /** @nullable */
  orgId: string | null;
  contractId: string;
  yearMonth: string;
  /** @nullable */
  duePrincipal: number | null;
  /** @nullable */
  dueInterest: number | null;
  outstandingAmount: number;
}
export interface DwOutstandingPrincipal {
  contractId: string;
  yearMonthDay: string;
  outstandingAmount: number;
}
export interface DwLoanPaymentActuals {
  orgId: string;
  contractId: string;
  yearMonth: string;
  /** @nullable */
  paidPrincipal: number | null;
  /** @nullable */
  paidInterest: number | null;
}
export interface DwCustomerBudget {
  orgId: string;
  contractId: string;
  yearMonth: string;
  cash: number;
  netIncome: number;
  otherIncome: number;
  cogs: number;
  otherExtCosts: number;
  personnelCosts: number;
  depreciation: number;
  financialNet: number;
  taxes: number;
  cashAccumulated: number;
  netIncomeAccumulated: number;
  otherIncomeAccumulated: number;
  cogsAccumulated: number;
  otherExtCostsAccumulated: number;
  personnelCostsAccumulated: number;
  depreciationAccumulated: number;
  financialNetAccumulated: number;
  taxesAccumulated: number;
}
export interface DwCustomerBudgetNonAccumulative {
  contractId: string;
  yearMonth: string;
  cash: number;
  netIncome: number;
  otherIncome: number;
  cogs: number;
  otherExtCosts: number;
  personnelCosts: number;
  depreciation: number;
  financialNet: number;
  taxes: number;
}
export interface DwAErpConnectedOrg {
  orgId: string;
  erpSystem: string;
  /** @nullable */
  montoCompanyUUID: string | null;
  /** @nullable */
  externalToken: string | null;
  connectedAt: string;
  /** @nullable */
  connectedBy: string | null; // customer personnummer or staffer email
}
export interface DwAccountingOrgYear {
  orgId: string;
  fyFirstMonth: string;
  periodCount: number;
  updated?: string;
  /** @nullable */
  updatedBy: string | null;
}
export interface ErpAccountingRecord {
  orgId: string;
  fyFirstMonth: string;
  fyLastMonth: string;
  level: number;
  accountNumber: number;
  accountName: string;
  openingAmount: number;
  closingAmount: number;
  periodTotal: number;
  /** @nullable */
  difference?: number | null;
}
export interface ErpOrgSourceUnbalancedAccounts {
  orgId: string;
  s3BucketAlias: string | null;
  s3ObjectKey: string | null;
  originalSourceInfo?: BlobMetadata | null;
  fyStartMonth: string;
  /** @nullable */
  verifiedBy: string | null;
  /** @nullable */
  verifiedReason: string | null;
  /** @nullable */
  updated: string | null;
  accounts: ErpAccountingRecord[] | null;
}
export interface AccountMovement {
  period: number;
  closing: number;
}
export interface DwCustomerActuals {
  yearMonth: string;
  orgId: string;
  finIntTangAssets: AccountMovement;
  wip: AccountMovement;
  receivables: AccountMovement;
  stAssetsPrepaid: AccountMovement;
  cash: AccountMovement;
  equity: AccountMovement;
  untaxedReserves: AccountMovement;
  ltDebt: AccountMovement;
  stDebt: AccountMovement;
  taxesVat: AccountMovement;
  prepaidIncome: AccountMovement;
  netIncome: AccountMovement;
  otherIncome: AccountMovement;
  cogs: AccountMovement;
  otherExtCosts: AccountMovement;
  personnelCosts: AccountMovement;
  depreciation: AccountMovement;
  financialNet: AccountMovement;
  other: AccountMovement;
}
export interface CustomerActualsRaw {
  orgId: string;
  yearMonth: string;
  accountNo: string;
  updated: string;
  /** @nullable */
  updatedBy: string;
  level: number;
  accountName: string;
  periodAmount: number;
  closingAmount: number;
}
export interface DwCustomerBudActs {
  budgets: DwCustomerBudget[];
  actuals: DwCustomerActuals[];
  initialSchedule: DwLoanPaymentSchedule[];
  paymentActuals: DwLoanPaymentActuals[];
  outPrincActuals: DwLoanPaymentSchedule[];
}
export interface DwCustomerBudgetEndDate {
  contractId: string;
  /** @nullable */
  endDate: string;
}
export interface DwLoanRepaymentAmounts {
  contractId: string;
  balancePrincipal1410: number;
  balanceInterest1510: number;
  unpaidCharges: number;
  accruedInterestPrevDay: number;
  accruedPenalInterestPrevDay: number;
}
export interface DwEcbLoan {
  organizationNumber: string;
  groupCompIdAs8: string;
  counterpartyIdAs7: string;
  contractIdAs3: string;
  /** @nullable */
  postcodeAs16: string | null;
  /** @nullable */
  oblIncorporationDateAs19: string | null;
  currentBalanceAs55: number;
  loanOrgDateAs50: string;
  maturityDateAs51: string;
  loanActive: boolean;
  originalLoanBalanceAs54: number;
  currentInterestRateAs80: number;
  interestRateMarginAs85As82: number;
  principalPaymentFreqAs58: string;
  /** @nullable */
  interestPaymentFreqAs59: string | null;
  /** @nullable */
  geographicRegionAs17: string | null;
  /** @nullable */
  balloonAmountAs66: number | null;
  /** @nullable */
  seniorityAs26: string | null;
  /** @nullable */
  gracePeriodEndDateAs70: string | null;
  ppFeeEndDateCrpl7072: string;
  /** @nullable */
  naceIndustryCodeAs42: string | null;
  amortisationTypeAs62: string;
  /** @nullable */
  enterpriseSizeCrpl16: string | null;
  /** @nullable */
  creditImpairedObligorCrpl12: boolean | null;
  /** @nullable */
  obligorLegalFormAs18: string | null;
  /** @nullable */
  repaymentMethodAs68: string | null;

  /** @nullable */
  interestArrearsAs115: number | null;
  /** @nullable */
  daysInterestArrearsAs116: number | null;
  /** @nullable */
  balanceOfArrearsAs117: number | null;
  /** @nullable */
  daysArrearsAs118: number | null;
  /** @nullable */
  paymentDueAs67: number | null;
  /** @nullable */
  paymentDueDate: string | null;
  /** @nullable */
  dateLastInArrearsCrpl76: string | null;
  /** @nullable */
  prepaymentDateCrpl73: string | null;
  /** @nullable */
  cumulativePrepaymentsCrpl74: number | null;

  // fields from loan_data_ecb:
  /** @nullable */
  poolAdditionDateCrpl7: string | null;
  /** @nullable */
  dateOfRepurchaseCrpl8: string | null;
  /** @nullable */
  redemptionDateCrpl9: string | null;
  /** @nullable */
  securitisedReceivablesCrpl25: string | null;
  /** @nullable */
  syndicatedCrpl28: boolean | null;
  /** @nullable */
  leveragedTransactionCrpl29: boolean | null;
  /** @nullable */
  paymentInKindCrpl31: boolean | null;
  /** @nullable */
  purchasePriceCrpl43: number | null;
  /** @nullable */
  nofPaymentsBeforeSecCrpl68: number | null;
  /** @nullable */
  accountStatusCrpl79: string | null;
  /** @nullable */
  defaultAmountCrpl81: number | null;
  /** @nullable */
  defaultDateCrpl82: string | null;
  /** @nullable */
  allocatedLossesCrpl83: number | null;
  /** @nullable */
  cumulativeRecoveriesCrpl84: number | null;
  /** @nullable */
  recoverySourceCrpl85: string | null;
  /** @nullable */
  revenueCrpl17: number | null;
  /** @nullable */
  totalDebtCrpl18: number | null;
  /** @nullable */
  ebitdaCrpl19: number | null;
  /** @nullable */
  enterpriseValueCrpl20: number | null;
  /** @nullable */
  freeCashflowCrpl21: number | null;
  /** @nullable */
  dateOfFinancialsCrpl22: string | null;
  /** @nullable */
  reasonDefaultForeclosureCrpl80: string | null;
  /** @nullable */
  originationChannelCrpl35: string | null;
  /** @nullable */
  purposeCrpl36: string | null;
  /** @nullable */
  triggerMeasurementsIvss12: boolean | null;
  /** @nullable */
  periodPrincipalRecoveriesIvss14: number | null;
  /** @nullable */
  periodInterestRecoveriesIvss15: number | null;
  /** @nullable */
  repurchasedExposuresIvss25: number | null;
  /** @nullable */
  restructuredExposuresIvss26: number | null;
  /** @nullable */
  customerTypeCrpl13: string | null;
  /** @nullable */
  excessSpreadTrappedIvss20: boolean | null;
  /** @nullable */
  dateOfRestructuringCrpl75: string | null;
  /** @nullable */
  specialSchemeCrpl32: string | null;
  /** @nullable */
  internalLgdEstimateAs37: number | null;
  /** @nullable */
  defaultPerTransDefinitionAs121: boolean | null;
  /** @nullable */
  defaultPerBasel3DefinitionAs122: boolean | null;
  /** @nullable */
  lossAllocationDateAs134: string | null;
  /** @nullable */
  eligibleAssetsAs139: boolean | null;
  /** @nullable */
  exfClaimAmountRcvdAs141: number | null;

  /** @nullable */
  guaranteeFeeRate: number | null;
  /** @nullable */
  totalTurnover: number | null;
  /** @nullable */
  totalAssets: number | null;
  /** @nullable */
  partOfGroup: boolean | null;
  /** @nullable */
  lossGivenDefault: number | null;
  /** @nullable */
  valueCollateral: number | null;
  /** @nullable */
  mainTypeCollateral: string | null;
  /** @nullable */
  indiCriteria: string | null;
  /** @nullable */
  transactionPurpose: string | null;
  /** @nullable */
  refinanced: boolean | null;
  /** @nullable */
  unperfected: boolean | null;
  /** @nullable */
  perfectedAt: string | null;
  /** @nullable */
  loanType: string | null;

  // fields from servicer_report():
  /** @nullable */
  initialUcScoreAs142: string | null;
  /** @nullable */
  currentUcScoreAs143: string | null;
  /** @nullable */
  currentUcAssignDateAs144: string | null;
  /** @nullable */
  revenueWaterfallIvss17: number | null;
  /** @nullable */
  principalWaterfallIvss16Ivss23: number | null;
  /** @nullable */
  ppAmounts: number | null;
  /** @nullable */
  periodIntRecovIvss15: number | null;
  /** @nullable */
  sDate: string | null;
  /** @nullable */
  cDate: string | null;

  /** @nullable */
  dbtInv: string | null;
  /** @nullable */
  paymentScheduleType: string | null;
}
export interface DwEcbCollateral {
  collateralIdCs1: string;
  loanIdCs2: string;
  currentValuationAmountCs4Cs10: number;
  nominalClaim: number;
  /** @nullable */
  origValuationDateCs11: string | null;
  securityTypeCs3: string;
  collateralTypeCs6: string;
  refSeniorityAs26: string;
  /** @nullable */
  guarantorCountryCrpc19: string | null;
  /** @nullable */
  guarantorEsaSubsectorCrpc20: string | null;
  /** @nullable */
  dateOfSaleCrpc16: string | null;
  /** @nullable */
  salePriceCrpc17: number | null;
  /** @nullable */
  currentValuationMethodCrpc11: string | null;
  /** @nullable */
  nominalValueCeiling: number | null;
  /** @nullable */
  collateralType: string | null;
  /** @nullable */
  collateralSubtype: string | null;
  /** @nullable */
  originalValuationMethodCrpc14: string | null;
  /** @nullable */
  geoRegionCollateralCrpc5: string | null;
}
export interface DwOverduePayment {
  contractId: string;
  organizationName: string;
  counterpartyId: string;
  paidDate: string;
  /** @nullable */
  paidAmount: number | null;
  /** @nullable */
  overDueNotPaidPrincipal: number | null;
  /** @nullable */
  overDueNotPaidInterest: number | null;
}
export interface CounterpartyMapping {
  // (replaces DwCounterpartyIds)
  /** @nullable */
  hubspotOrgId: string | null;
  /** @nullable */
  orgId: string | null;
  /** @nullable */
  dbtCoreCustomerId: string | null;
  /** @nullable */
  companyName: string | null;
  /** @nullable */
  createdDate: string | null;
  /** @nullable */
  stafferEmail: string | null;
}
export interface HubspotOrg {
  // (replaces OpsCounterparty)
  hubspotOrgId: string;
  orgId: string;
  /** @nullable */
  companyName: string | null;
  createdDate: string;
}
export interface FyMetadata {
  /** @nullable */
  firstMonth: string | null; // YYYY-mm
  /** @nullable */
  lastMonth: string | null; // YYYY-mm
  /** @nullable */
  lastDataDate: string | null; // YYYY-mm-dd
  /** @nullable */
  dataGeneratedDate: string | null; // YYYY-mm-dd
  /** @nullable */
  updated: string | null; // when data for this year where updated
  /** @nullable */
  updatedBy: string | null; // who updated the data (customer SSN or staffer email)
}
export interface CounterpartyWithStaffer {
  hubspotOrgId: string;
  orgId: string;
  /** @nullable */
  companyName: string | null;
  /** @nullable */
  stafferId: string | null;
  /** @nullable */
  representingRole: string | null;
  /** @nullable */
  createdDate: string | null;
  /** @nullable */
  connectedErp: string | null;
  /** @nullable */
  erpConnectedAt: string | null;
  /** @nullable */
  erpConnectedBy: string | null; // SSN or staffer email
  /** @nullable */
  erpRequestedAt: string | null; // when last ERP connect invitation was sent
  /** @nullable */
  erpAuthorizedAt: string | null; // when as customer authorized to ERP
  /** @nullable */
  erpCompanyId: string | null; // referenced monto company uuid of the authorized
  /** @nullable */
  erpSubSystem: string | null;
  /** @nullable */
  fyFirstMonths: string[] | null;
  /** @nullable */
  fyLastMonths: string[] | null;
  /** @nullable */
  fyMetadata: FyMetadata[];
  /** @nullable */
  dbtCustomerCoreId: string | null;
  /** @nullable */
  activeContracts: number | null;
  /** @nullable */
  totalContracts: number | null;
  /** @nullable */
  contractIds: string | null;
  /** @nullable */
  activeDeals: string[] | null;
  /** @nullable */
  firstManualSie: string | null;
  /** @nullable */
  lastManualSie: string | null;
}
export interface OpsStaffCounterpartyRelation {
  stafferId: string;
  //hubspotOrgId: string,
  orgId: string;
  representingRole: string;
}
export interface DwCustomerOrg {
  dbtCoreCustomerId: string;
  organizationName: string;
  orgId: string;
}

/** Contains the auxiliary data fields for a customer. */
export interface AuxCustomer {
  counterpartyId: string;
  consistentRecord: boolean | null; // automatic
  // organisation
  registrationDate: string;
  // counterparty_data_ecb:
  /** @nullable */
  creditImpairedObligorCrpl12: boolean | null;
  enterpriseSizeCrpl16: string;
}

/** Contains the auxiliary data fields for a loan. */
export interface AuxLoan {
  contractId: string;
  consistentRecord: boolean | null; // automatic
  // ECL
  /** @nullable */
  classification: string;
  /** @nullable */
  pdDbt: number;
  /** @nullable */
  dateOfAnalysis: string;
  // fields from loan_data_ecb:
  /** @nullable */
  poolAdditionDateCrpl7: string | null;
  /** @nullable */
  dateOfRepurchaseCrpl8: string | null;
  /** @nullable */
  redemptionDateCrpl9: string | null;
  /** @nullable */
  securitisedReceivablesCrpl25: string | null;
  /** @nullable */
  syndicatedCrpl28: boolean | null;
  /** @nullable */
  leveragedTransactionCrpl29: boolean | null;
  /** @nullable */
  paymentInKindCrpl31: boolean | null;
  /** @nullable */
  purchasePriceCrpl43: number | null;
  /** @nullable */
  nofPaymentsBeforeSecCrpl68: number | null;
  /** @nullable */
  accountStatusCrpl79: string | null;
  /** @nullable */
  defaultAmountCrpl81: number | null;
  /** @nullable */
  defaultDateCrpl82: string | null;
  /** @nullable */
  allocatedLossesCrpl83: number | null;
  /** @nullable */
  cumulativeRecoveriesCrpl84: number | null;
  /** @nullable */
  recoverySourceCrpl85: string | null;
  /** @nullable */
  revenueCrpl17: number | null;
  /** @nullable */
  totalDebtCrpl18: number | null;
  /** @nullable */
  ebitdaCrpl19: number | null;
  /** @nullable */
  enterpriseValueCrpl20: number | null;
  /** @nullable */
  freeCashflowCrpl21: number | null;
  /** @nullable */
  dateOfFinancialsCrpl22: string | null;
  /** @nullable */
  reasonDefaultForeclosureCrpl80: string | null;
  /** @nullable */
  originationChannelCrpl35: string | null;
  /** @nullable */
  purposeCrpl36: string | null;
  /** @nullable */
  triggerMeasurementsIvss12: boolean | null;
  /** @nullable */
  periodPrincipalRecoveriesIvss14: number | null;
  /** @nullable */
  periodInterestRecoveriesIvss15: number | null;
  /** @nullable */
  repurchasedExposuresIvss25: number | null;
  /** @nullable */
  restructuredExposuresIvss26: number | null;
  /** @nullable */
  borrowingBase: boolean | null;
  /** @nullable */
  customerTypeCrpl13: string | null;
  /** @nullable */
  excessSpreadTrappedIvss20: boolean | null;
  /** @nullable */
  dateOfRestructuringCrpl75: string | null;
  /** @nullable */
  specialSchemeCrpl32: string | null;
  /** @nullable */
  internalLgdEstimateAs37: number | null;
  /** @nullable */
  defaultPerTransDefinitionAs121: boolean | null;
  /** @nullable */
  defaultPerBasel3DefinitionAs122: boolean | null;
  /** @nullable */
  lossAllocationDateAs134: string | null;
  /** @nullable */
  eligibleAssetsAs139: boolean | null;
  /** @nullable */
  exfClaimAmountRcvdAs141: number | null;
  /** @nullable */
  paymentScheduleType: string | null;
  /** @nullable */
  guaranteeFeeRate: number | null;
  /** @nullable */
  totalTurnover: number | null;
  /** @nullable */
  totalAssets: number | null;
  /** @nullable */
  partOfGroup: boolean | null;
  /** @nullable */
  lossGivenDefault: number | null;
  /** @nullable */
  valueCollateral: number | null;
  /** @nullable */
  mainTypeCollateral: string | null;
  /** @nullable */
  indiCriteria: string | null;
  /** @nullable */
  transactionPurpose: string | null;
  /** @nullable */
  refinanced: boolean | null;
  /** @nullable */
  unperfected: boolean | null;
  /** @nullable */
  perfectedAt: string | null;
  /** @nullable */
  loanType: string | null;
}

/** Contains the DW data for a collateral. */
export interface DwCollateral {
  // from dwd_collaterals:
  collateralId: string;
  collateralType: string;
  /** @nullable */
  collateralSubtype: string | null;
  description: string;
  appraisedCollateralValue: number;
  loanLinkage: DwLoanCollateral[];
  auxCollateral: AuxCollateral;
}

/** Contains the data for a collateral-loan linkage. */
export interface DwLoanCollateral {
  // from dwd_contract_collat_linkage:
  contractId: string;
  collateralPriority: number;
  collateralContribution: number;
}

/** Contains the auxiliary data fields for a collateral. */
export interface AuxCollateral {
  collateralId: string;
  consistentRecord: boolean | null; // automatic

  // from collateral_data_ecb:
  /** @nullable */
  dateOfSaleCrpc16: string | null;
  /** @nullable */
  salePriceCrpc17: number | null;
  /** @nullable */
  currentValuationMethodCrpc11: string | null;
  /** @nullable */
  guarantorCountryCrpc19: string | null;
  /** @nullable */
  guarantorEsaSubsectorCrpc20: string | null;
  originalValuationMethodCrpc14: string;
  /** @nullable */
  geoRegionCollateralCrpc5: string | null;
}

/** Adapted from AWS API OutputLogEvent type. */
export interface MtLogEvent {
  /** The time the event occurred, expressed as the number of milliseconds after Jan 1, 1970 00:00:00 UTC. */
  timestamp?: number;
  /** The data contained in the log event. */
  message?: string;
  /** The time the event was ingested, expressed as the number of milliseconds after Jan 1, 1970 00:00:00 UTC. */
  ingestionTime?: number;
}

/** Adapted from AWS API GetLogEventsResponse type. */
export interface MtLogEventsResponse {
  /** The events. */
  events?: MtLogEvent[];
  /** The token for the next set of items in the forward direction. The token expires after 24 hours. If you have reached the end of the stream, it returns the same token you passed in. */
  nextForwardToken?: string;
  /** The token for the next set of items in the backward direction. The token expires after 24 hours. This token is never null. If you have reached the end of the stream, it returns the same token you passed in. */
  nextBackwardToken?: string;
}
export interface SwePostalCodes {
  zipCode: string;
  zipCodeDistrict: string;
  county: string;
  countyCode: string;
  municipality: string;
  municipalityCode: string;
  regionCode: string;
  nutsCode: string;
}
export interface SegmentBenchmark {
  operationMargin: number;
  solidity: number;
  cashLiquidity: number;
  changeInNetSales: number;
  /** @nullable */
  grossNetMarginal: number | null;
}

////////////////////////////////
// Customer users admin endpoints

export type UserReprStatus = "unverified" | "verified" | "admin" | "removed";
export interface CpRepresentationRole {
  persNo: string;
  orgId: string;
  hubspotOrgId: string;
  version: number;
  updated: string;
  status: UserReprStatus;
  /** @nullable */
  orgName: string | null;
  /** @nullable */
  stafferEmail: string | null;
  /** @nullable */
  representingRole: string | null;
  /** @nullable */
  dbtCustomerCoreId: string | null;
  /** @nullable */
  activeContracts: number | null;
  /** @nullable */
  totalContracts: number | null;
  /** @nullable */
  contractIds: string | null;
}
export interface CpUser {
  persNo: string;
  version: number;
  updated: string;
  suspended: boolean;
  userName: string;
  /** @nullable */
  email: string | null;
  firstLoginTime: string;
  lastLoginTime: string;
  representations: CpRepresentationRole[];
}
export interface CpStaff {
  email: string;
  firstName: string;
  lastName: string;
  jobRole: string;
  /** @nullable */
  linkedinUrl: string | null;
  phoneNr: string;
  /** @nullable */
  photoUrl: string | null;
  /** @nullable */
  meetingBookingUrl: string | null;
  /** @nullable */
  vidyardGreetingUrl: string | null;
}
export interface CpStaffWithCompany extends CpStaff {
  orgId: string;
  /** @nullable */
  companyName: string | null;
}
export interface UserInvitation {
  persNo: string;
  orgId: string;
  /** @nullable */
  invitedByStaffer: string | null;
  /** @nullable */
  invitedByUser: string | null;
  invitedTime: string;
  validInterval: string;
  /** @nullable */
  presetStatus: UserReprStatus | null;
}
export interface emailSuccess {
  accepted: string[];
  rejected: string[];
  envelopeTime: number;
  messageSize: number;
  response: string;
  envelope: {
    from: string;
    to: string[];
  };
  messageId: string;
}
export type triggerFortnoxIntegrationSuccess = emailSuccess;
export interface CpLoansOverview {
  // TODO: remove nextPaymentAmount & nextPaymentDate, use nextPayment instead
  nextPaymentDate: string;
  nextPaymentAmount: number;
  nextPayment: DwLoanPaymentSchedule | undefined;
  nextPayments: (DwLoanPaymentSchedule | undefined)[];
  totalToRepay: number;
  totalRepaid: number;
  loans: CpLoan[];
}
export interface CpLoan {
  loanContract: DwLoanContract;
  schedule: DwLoanPaymentSchedule[];
}
export interface OpsLedgerSplit {
  contractId: string;
  ledger: string;
  validFrom: string;
  validTo: string;
}

// A file uploaded to the document repository
export interface BlobMetadata {
  blobId: string;
  created: string;
  originalFileName: string;
  originalMediaType: string;
  // In case BLOB is uploaded by a staff member: id and minimal visual representation
  createdByStaff?: {
    email: string;
    firstName: string;
    lastName: string;
  };
  // In case BLOB uploaded by a customer: personnummer and minimal visual representation
  createdByUser?: {
    persNo: string;
    suspended: boolean;
    userName: string;
  };
  // In case BLOB uploaded by a service: client_id
  createdByService?: string;
}

// a versioned document in the document repository
export interface DocumentRepositoryItem {
  documentId: string;
  versions: BlobMetadata[]; // always nonempty, versions in reverse chronological order, current is the first
  isArchived: boolean;
}

// a slot description (used to create new slots)
export interface DocumentRepositorySlotDescription {
  displayName: string;
  isMandatory: boolean;
  isHidden: boolean; // technical slots, normal users are not supposed to directly interact with hidden slots
  canContainMultipleDocuments: boolean;
  readPerm: number; // used as view permission bit flag (0, 1, 2, 3)
}

// slot: description, id and content
export interface DocumentRepositorySlot extends DocumentRepositorySlotDescription {
  slotId: string;
  documents: DocumentRepositoryItem[]; // documents in reverse chronological order of creation
  isArchived: boolean; // archived slots, kept for traceability
}
export interface UcFinancials {
  orgId: string;
  fromDate: string;
  toDate: string;
  /** @nullable */
  netSales: number | null;
  /** @nullable */
  changeInInventoriesOfProductsEtc: number | null;
  /** @nullable */
  costOfGoodsSold: number | null;
  /** @nullable */
  otherOperatingIncome: number | null;
  /** @nullable */
  totalOperatingIncome: number | null;
  /** @nullable */
  workPerformedByCompanyForOwnUseAndCapitalised: number | null;
  /** @nullable */
  rawMaterialConsumables: number | null;
  /** @nullable */
  goodsForResale: number | null;
  /** @nullable */
  otherExternalCosts: number | null;
  /** @nullable */
  personnelCosts: number | null;
  /** @nullable */
  depreciationAmortisation: number | null;
  /** @nullable */
  grossProfitLoss: number | null;
  /** @nullable */
  sellingExpenses: number | null;
  /** @nullable */
  administrativeExpenses: number | null;
  /** @nullable */
  researchAndDevelopmentCosts: number | null;
  /** @nullable */
  itemsAffectingComparability: number | null;
  /** @nullable */
  otherOperatingExpense: number | null;
  /** @nullable */
  totalCostsAndDepreciation: number | null;
  /** @nullable */
  operatingProfit: number | null;
  /** @nullable */
  resultFromParticipationInGroupAndAssociatedCompanies: number | null;
  /** @nullable */
  interestIncomeGroupCompanies: number | null;
  /** @nullable */
  externalInterestIncome: number | null;
  /** @nullable */
  otherFinancialIncome: number | null;
  /** @nullable */
  totalFinancialIncome: number | null;
  /** @nullable */
  interestExpenseToGroupCompanies: number | null;
  /** @nullable */
  externalInterestExpenses: number | null;
  /** @nullable */
  otherFinancialExpenses: number | null;
  /** @nullable */
  totalFinancialExpenses: number | null;
  /** @nullable */
  financialItemsAffectingComparability: number | null;
  /** @nullable */
  profitLossAfterFinancialIncomeAndExpenses: number | null;
  /** @nullable */
  extraordinaryIncome: number | null;
  /** @nullable */
  extraordinaryExpense: number | null;
  /** @nullable */
  resultShareholdersContribution: number | null;
  /** @nullable */
  profitLossBeforeAppropriations: number | null;
  /** @nullable */
  appropriations: number | null;
  /** @nullable */
  resultGroupContribution: number | null;
  /** @nullable */
  profitLossBeforeTax: number | null;
  /** @nullable */
  tax: number | null;
  /** @nullable */
  minorityInterestsProfitLossSubsidiariesBeforeAcquisition: number | null;
  /** @nullable */
  resultNetProfitLossForYear: number | null;
  /** @nullable */
  netProfitLoss: number | null;
  /** @nullable */
  capitalisedExpenditureForRAndD: number | null;
  /** @nullable */
  concessionsPatentsLicencesTrademarksAndSimilarRights: number | null;
  /** @nullable */
  goodwill: number | null;
  /** @nullable */
  otherIntangibleFixedAssets: number | null;
  /** @nullable */
  totalIntangibleFixedAssets: number | null;
  /** @nullable */
  landAndBuildings: number | null;
  /** @nullable */
  machinery: number | null;
  /** @nullable */
  equipment: number | null;
  /** @nullable */
  totalMachineryAndEquipment: number | null;
  /** @nullable */
  otherTangibleFixedAssets: number | null;
  /** @nullable */
  totalTangibleFixedAssets: number | null;
  /** @nullable */
  participationInGroupAndAssociatedCompanies: number | null;
  /** @nullable */
  receivablesOnGroupAndAssociatedCompanies: number | null;
  /** @nullable */
  receivablesFromGroupAndAssociatedCompanies: number | null;
  /** @nullable */
  loansToPartnersAndRelatedParties: number | null;
  /** @nullable */
  otherFinancialFixedAssets: number | null;
  /** @nullable */
  totalFinancialFixedAssets: number | null;
  /** @nullable */
  otherFixedAssets: number | null;
  /** @nullable */
  totalFixedAssets: number | null;
  /** @nullable */
  workInProgressOnContract: number | null;
  /** @nullable */
  otherInventories: number | null;
  /** @nullable */
  totalInventories: number | null;
  /** @nullable */
  accountsReceivable: number | null;
  /** @nullable */
  otherCurrentReceivables: number | null;
  /** @nullable */
  totalCurrentReceivables: number | null;
  /** @nullable */
  totalCurrentInvestments: number | null;
  /** @nullable */
  totalCashAndBankBalances: number | null;
  /** @nullable */
  otherCurrentAssets: number | null;
  /** @nullable */
  totalCurrentAssets: number | null;
  /** @nullable */
  totalAssets: number | null;
  /** @nullable */
  shareCapital: number | null;
  /** @nullable */
  sharePremiumReserve: number | null;
  /** @nullable */
  revaluationReserve: number | null;
  /** @nullable */
  otherRestrictedEquity: number | null;
  /** @nullable */
  retainedEarnings: number | null;
  /** @nullable */
  balanceNetProfitLossForYear: number | null;
  /** @nullable */
  totalShareholdersEquity: number | null;
  /** @nullable */
  totalUntaxedReserves: number | null;
  /** @nullable */
  minorityInterest: number | null;
  /** @nullable */
  totalProvisions: number | null;
  /** @nullable */
  bondLoans: number | null;
  /** @nullable */
  longTermLiabilitiesToCreditInstitutions: number | null;
  /** @nullable */
  ofWhichBankOverdraftFacilitiesUtilised: number | null;
  /** @nullable */
  longTermLiabilitiesToGroupAndAssociatedCompanies: number | null;
  /** @nullable */
  longTermInterestBearingLiabilities: number | null;
  /** @nullable */
  totalLongTermLiabilities: number | null;
  /** @nullable */
  currentLiabilitiesToCreditInstitutions: number | null;
  /** @nullable */
  accountsPayableTrade: number | null;
  /** @nullable */
  currentLiabilitiesToGroupAndAssociatedCompanies: number | null;
  /** @nullable */
  otherCurrentLiabilities: number | null;
  /** @nullable */
  totalCurrentLiabilities: number | null;
  /** @nullable */
  totalShareholdersEquityAndLiabilities: number | null;
  /** @nullable */
  subscribedCapitalUnpaid: number | null;
  /** @nullable */
  balanceGroupContribution: number | null;
  /** @nullable */
  balanceShareholdersContribution: number | null;
  /** @nullable */
  netInterestIncomeExpense: number | null;
  /** @nullable */
  salariesToBoardOfDirectorsManagingDirector: number | null;
  /** @nullable */
  salariesToOthers: number | null;
  /** @nullable */
  totalSalaries: number | null;
  /** @nullable */
  bonusToBoardOfDirectors: number | null;
  /** @nullable */
  performancePayToEmployees: number | null;
  /** @nullable */
  totalWagesSalariesAndRemuneration: number | null;
  /** @nullable */
  payrollOverheads: number | null;
  /** @nullable */
  dividend: number | null;
  /** @nullable */
  contractedSeverancePay: number | null;
  /** @nullable */
  totalDepreciationAmortisation: number | null;
  /** @nullable */
  totalDepreciationAmortisationDepreciationCostGoodsSold: number | null;
  /** @nullable */
  totalDepreciationAmortisationDepreciationSellingExpenses: number | null;
  /** @nullable */
  totalDepreciationAmortisationDepreciationAdminExpenses: number | null;
  /** @nullable */
  totalDepreciationAmortisationDepreciationRAndDCosts: number | null;
  /** @nullable */
  totalDepreciationAmortisationDepreciationOther: number | null;
  /** @nullable */
  overdraftFacilitiesGranted: number | null;
  /** @nullable */
  utilisedOverdraftFacilities: number | null;
  /** @nullable */
  floatingCharges: number | null;
  /** @nullable */
  propertyMortgages: number | null;
  /** @nullable */
  otherCollateral: number | null;
  /** @nullable */
  otherContingentLiabilities: number | null;
  /** @nullable */
  issues: number | null;
  /** @nullable */
  conditionalShareholdersContribution: number | null;
  /** @nullable */
  grossProfit: number | null;
  isGroup: boolean;
  source: string;
}
export interface UcFinancialsSummary {
  orgId: string;
  fromDate: string;
  toDate: string;
  isGroup: boolean;
  source: string;
  revenue: number;
  EBITA: number;
  assets: number;
  equity: number;
}
export enum JOB_TYPE {
  SIE = "sie",
}
export enum JOB_STATUS {
  STARTED = "started",
  FINISHED = "finished",
  FAILED = "failed",
}
export interface JobSieData {
  orgId: string;
  originalFileName: string;
  blobId?: string;
}
export interface Job<T> {
  id: number;
  type: JOB_TYPE;
  data: T;
  isArchived: boolean;
  lastStatus: {
    status: JOB_STATUS;
    created: string;
    statusDetails?: Record<PropertyKey, string | number>;
  };
}
export interface Deal {
  dealId: string;
  dealName: string;
  orgId: string;
  relevantOrgIds: string[] | null;
  loanId: string | null;
  stafferId: string | null;
  hubspotStatus: string | null;
  version: number;
  updated: string;
  updatedBy: string;
  dealValue: string | null;
  closedDate: string | null;
}
export interface DealWithErpConnection extends Deal {
  connectedErp: string | null;
}
export interface DealOrgRole {
  dealId: string;
  orgId: string;
  role: string;
  roleSpec: string | null;
  roleComment: string | null;
  dealOrgId: string | null;
  archived: boolean;
  version: number;
  updated: string;
  updatedBy: string;
}
export interface Organization {
  orgId: string;
  name: string;
  registrationDate: string | null;
  deregistrationDate: string | null;
  infoSource: string;
  /** timestamp when first created in DB */
  created: string;
  /** timestamp when recently updated in DB */
  updated: string;
  updatedBy: string;
  /** @deprecated */
  validFrom: string | null;
  /** @deprecated */
  validTo: string | null;
  /** @deprecated */
  version: number | null;
}
export interface OrgOwnership {
  orgId: string;
  ownerId: string;
  ownershipSpec: string;
  infoSource: string;
  updatedBy: string;
  updated: string;
}
export interface Company {
  orgId: string;
  name: string;
  ownership: string;
  address: {
    street: string;
    zipCode: string;
    city: string;
    county?: string;
    municipality?: string;
    isPoBox: boolean;
    exposedArea: boolean;
  };
  updated: string;
}

/** @deprecated */
export interface OrganizationWithParent {
  orgId: string;
  organization: Organization;
  parentOrgId: string | null;
  ownershipSpec: string;
}
export interface OrganizationWithOwners {
  organization: Organization;
  owners: OrgOwnership[];
}
export interface ScorecardData {
  dealId?: string;
  /** @nullable */
  dbtCreditScore: number | null;
  /** @nullable */
  dbtPd: number | null;

  // Block 1
  /** @nullable */
  externalCreditRatingUc: number | null;
  ucPercentage: number; // UC probability of default percentage, 0..100

  // Block 2, all values are scores [1..5]
  /** @nullable */
  dbtScoreOverallBusiness: number | null;
  ownersFinancialSituation: number;
  ownersSkinInTheGame: number;
  managementsTrackRecord: number;
  technologicalOrRegulatoryRisk: number;
  concentrationRisk: number;
  transactionalRisk: number;
  seniorityRisk: number;
  historicalRisk: number | null; // null when no prev history

  // Block 3
  /** @nullable */
  dbtScoreFinancials: number | null;
  noiToPayoutRatio: number;
  netDebtToEbitdaRatio: number;
  comments: string;

  // Metadata
  version: number;
  updatedBy: string; // who updated the record
  updated: string; // when the record was updated
}
export interface LoanScorecard {
  contractId: string;
  counterpartyId: string;
  contractStatus: ContractStatus;
  disbursedAmount: number;
  dbtInv: string;
  dealId: string;
  scorecard: ScorecardData; // scorecard at origination
}
export interface ScanningResult {
  hit: boolean;
  date: string;
  type: string;
}

// Endpoint return
export interface PersonOfInterest {
  personId: string;
  fullName: string;
  displayFullName: string;
  role: string;
  dateRange: string[][];
  results: ScanningResult[];
}
export interface OrgMonitoringEvent {
  orgId: string;
  ucPdPercentage: number;
  event: object;
  updated: string;
}
export interface OrgUcScore {
  date: string;
  score?: number | null;
  reason?: string | null;
}

// DB Model
export interface PersonOfInterestTable {
  personId: string;
  fullName: string;
  displayFullName: string;
  orgId: string;
  role: string;
  /** @nullable */
  roleComment: string | null;
  dateRange: any[][];
  updatedBy: string;
  updated: string;
  version: number;
}
export interface KycScreeningResult {
  personId: string;
  fullName: string;
  type: string;
  /** @nullable */
  roaringId: string | null;
  hit: boolean;
}
export enum AgreementStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}
export interface Agreement {
  agreementId: string;
  orgId: string;
  dealId: string;
  status: AgreementStatus;
  amount: number;
  interest: number;
  fromDate: string;
  toDate: string;
  updatedBy: string;
  updated: string;
}
export enum DrawdownStatus {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  WITHDRAWN = "withdrawn",
}
export interface Drawdown {
  drawdownId: number;
  agreementId: string;
  /** @nullable */
  contractId: string | null;
  requestedDate: string;
  requestedAmount: number;
  requestedLoanPeriod: number;
  requestedGracePeriod: number;
  status: DrawdownStatus;
  /** @nullable */
  decisionDate: string | null;
  /** @nullable */
  approvedAmount: number | null;
  /** @nullable */
  approvedLoanPeriod: number | null;
  /** @nullable */
  approvedGracePeriod: number | null;
  /** @nullable */
  approvedBy: string | null;
  updatedBy: string;
  updated: string;
}
export interface Application {
  orgId: string;
  email: string;
  amount: number;
  amountString: string;
  updated: string;
  createdAt: string;
}
export interface AnalysisModel {
  dealId: string;
  orgId: string;
  data: object;
  gid: string;
  /** @nullable */
  memoGid: string | null;
  updatedBy: string;
  updated: string;
}
export interface KycompanyScreeningResult {
  orgId: string;
  type: string;
  hit: boolean;
  updated?: string;
}
export interface Note {
  id: number;
  /** @nullable */
  contractId: number | null;
  /** @nullable */
  ocr: string;
  updated: string | null;
  /** @nullable */
  updatedBy: string | null;
  /** @nullable */
  content: string | null;
  /** @nullable */
  type: string | null;
  /** @nullable */
  dueDate: string | null;
  /** @nullable */
  assignee: string | null;
  archived: boolean;
}
export interface NoteWOAssociation {
  id: number;
  updated: string | null;
  /** @nullable */
  updatedBy: string | null;
  /** @nullable */
  content: string | null;
  /** @nullable */
  type: string | null;
  /** @nullable */
  dueDate: string | null;
  /** @nullable */
  assignee: string | null;
  archived: boolean;
}
export interface BolagsverketCorporatePledge {
  orgId: string;
  pledgeDate: string;
  amount: number;
  serialNumber: number;
  runningAmount: number;
  approved: boolean;
  updated: string | null;
}

// Monto Company and associated connection
export interface MontoConnection {
  montoCompanyUUID: string;
  montoConnectionUUID: string;
  intendedOrgId: string;
  createdBy: string | null;
  createdAt: string;
  authorizedAt: string | null;
}