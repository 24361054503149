import _styled from "@emotion/styled";
import { LoadingComponent } from "@dbt/commons/components/loading";
import { SessionModal } from "components/modals/SessionModal";
import { useAuth } from "hooks/contexts";
import LoggedIn from "LoggedIn";
import LoggedOut from "LoggedOut";
import { ModalProvider } from "ModalProvider";
import { AppProps as NextAppProps } from "next/app";
import React from "react";
import { SWRConfig } from "swr";
import { UserProvider } from "UserProvider";
import { Props } from "utils/getProps";
import { DrawerProvider } from "DrawerProvider";
import { useTranslation } from "next-i18next";
import { TemporaryUploadStateProvider } from "TemporaryUploadState";
const LoadingContainer = _styled.div({
  "display": "flex",
  "height": "100%",
  "width": "100%",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center"
});
const swrConfig = {
  shouldRetryOnError: false,
  revalidateOnFocus: false
};
export type AppProps<P = any> = {
  pageProps: Props;
} & Omit<NextAppProps<P>, "pageProps">;
export default function AppRoot(props: AppProps) {
  const {
    user
  } = useAuth();
  // Make sure translations are loading before rendering the page
  const {
    ready
  } = useTranslation(["BankID", "Application", "Common", "Drawdown", "Loans", "Reports", "Settings", "BookKeeping", "Documents"]);
  const {
    isAuthenticated,
    currentRepresentationOrgId
  } = props.pageProps;
  const loading = isAuthenticated && !user || !ready;
  if (loading) {
    return <LoadingContainer>
        <LoadingComponent size={120} loadingAreaName="initialRootLoading" data-testid="initialRootLoadingIndicator" />
      </LoadingContainer>;
  }
  if (user && isAuthenticated) {
    return <SWRConfig value={swrConfig}>
        <UserProvider orgId={currentRepresentationOrgId} user={user}>
          <ModalProvider>
            <SessionModal />
            <DrawerProvider>
              <TemporaryUploadStateProvider>
                <LoggedIn {...props} />
              </TemporaryUploadStateProvider>
            </DrawerProvider>
          </ModalProvider>
        </UserProvider>
      </SWRConfig>;
  }
  return <LoggedOut {...props} />;
}